
import { sleep } from '@/utils/helpers';
import { Debounce } from 'vue-debounce-decorator';
import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';

@Component
export default class EditorComponent extends Vue {
  @Prop({
    type: Array,
    default() {
      return ['for the next 7 days'];
    },
  })
  suggestions!: any;

  // suggestions: any = ['Waiting On Offset Frac for next 7 days'];

  @Model('change', { type: String }) defaultValue!: any;

  async focusEditor() {
    await sleep(500);
    if (this.$refs.editor) {
      (this.$refs.editor as HTMLDivElement).focus();
      console.log(this.$refs.editor);
    }
  }

  get beforeSuggestionHTML() {
    return this.isEmpty
      ? ''
      : this.beforeSuggestion +
          '<span id="suggestion-section" class="suggestion text-tasqNeutral200 italic">' +
          this.afterSuggestion +
          '</span>';
  }

  async created() {
    this.beforeSuggestion = this.defaultValue;
    this.$nextTick(() => {
      this.setCursorToEnd();
    });
    await this.focusEditor();
    this.$emit('get-suggestions', this.beforeSuggestion);
    this.filterSuggestions();
    await this.focusEditor();
  }

  beforeSuggestion = '';
  afterSuggestion = '';

  cursorPosition = 0;

  get placeholder(): string {
    return this.isEmpty ? 'Type here...' : '';
  }

  get isEmpty(): boolean {
    return !this.beforeSuggestion && !this.afterSuggestion;
  }

  // handleInput(event: Event) {
  //   const target = event.target as HTMLDivElement;
  //   const fullText = target.innerText;
  //   const suggestionSection = document.getElementById('suggestion-section');
  //   console.log(target.innerHTML);
  //   if (!fullText.trim()) {
  //     this.beforeSuggestion = '';
  //     this.afterSuggestion = '';
  //     this.$nextTick(() => {
  //       target.innerHTML = '<span id="suggestion-section" class="suggestion text-white005"></span>';
  //     });
  //   }
  //   const selection = window.getSelection();
  //   this.cursorPosition = Math.min(selection!.anchorOffset, selection!.focusOffset);
  //   this.beforeSuggestion = fullText.slice(0, this.cursorPosition);
  //   this.filterSuggestions();
  // }
  handleInput(event: Event) {
    const target = event.target as HTMLDivElement;
    const fullText = target.innerText;

    if (!fullText.trim()) {
      this.beforeSuggestion = '';
      this.afterSuggestion = '';
      this.$nextTick(() => {
        target.innerHTML =
          '<span id="suggestion-section" class="suggestion text-white005"></span>';
      });
    }

    // Save the cursor position
    const currentCursorPosition = this.getCursorPosition();

    const selection = window.getSelection();
    this.cursorPosition = Math.min(
      selection!.anchorOffset,
      selection!.focusOffset
    );
    this.beforeSuggestion = fullText.slice(0, this.cursorPosition);
    this.filterSuggestions();

    // Restore the cursor position
    this.$nextTick(() => {
      this.setCursorPosition(currentCursorPosition);
    });
  }

  setCursorPosition(pos: number) {
    const editorElem = this.$refs.editor as HTMLDivElement;
    const range = document.createRange();
    const sel = window.getSelection();

    if (editorElem && editorElem.childNodes) {
      range.setStart(editorElem.childNodes[0], pos);
      range.collapse(true);
      sel!.removeAllRanges();
      sel!.addRange(range);
    }
  }

  getCursorPosition(): number {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      return range.startOffset;
    }
    return 0;
  }
  filterSuggestions() {
    const lines = this.beforeSuggestion.split('\n');
    const currentLine = lines[lines.length - 1];
    const suggestionSection = document.getElementById('suggestion-section');
    const matched = this.suggestions.find((s) => s.startsWith(currentLine));
    if (matched && currentLine !== matched) {
      this.afterSuggestion = matched.slice(currentLine.length);
      if (suggestionSection) {
        suggestionSection.innerText = this.afterSuggestion;
      }
    } else {
      this.afterSuggestion = '';
      if (suggestionSection) {
        suggestionSection.innerText = this.afterSuggestion;
      }
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    console.log(event.key);
    if (event.key === 'Enter' && !this.afterSuggestion) {
      event.preventDefault();
      this.$emit('add-item');
    }
    if (
      (event.key === 'Tab' ||
        event.key === 'ArrowRight' ||
        event.key === 'Enter') &&
      this.afterSuggestion
    ) {
      this.beforeSuggestion += this.afterSuggestion;
      this.afterSuggestion = '';
      event.preventDefault();
      (this.$refs.editor as HTMLDivElement).innerText = this.beforeSuggestion;
      this.setCursorToEnd();
    } else if (event.key === 'Backspace') {
      event.preventDefault();
      this.afterSuggestion = '';
      this.beforeSuggestion = this.beforeSuggestion.slice(0, -1);
      this.filterSuggestions();
    }

    // this.$emit('keydown',event)
  }

  setCursorToEnd() {
    const editorElem = this.$refs.editor as HTMLDivElement;
    const range = document.createRange();
    const sel = window.getSelection();

    range.selectNodeContents(editorElem); // Select the whole content of the editor
    range.collapse(false); // Collapse the range to the end
    sel!.removeAllRanges(); // Remove all ranges
    sel!.addRange(range); // Add the new range
  }

  handleBlur() {
    this.afterSuggestion = '';
  }

  @Debounce(100)
  @Watch('beforeSuggestion')
  onTextInputChange(value: string) {
    console.log(this.beforeSuggestion);
    // this.$emit('get-suggestions', this.beforeSuggestion);
    this.$emit('change', this.beforeSuggestion);
    this.$emit('input', this.beforeSuggestion);
  }

  @Watch('suggestions')
  onSuggestionsChange(value: string) {
    this.filterSuggestions();
  }
}
